module.exports ={
  "propertyService": "https://d80rvxpfq4.execute-api.us-east-1.amazonaws.com/dennis",
  "domusoApiService": "https://qodcuaagwi.execute-api.us-east-1.amazonaws.com/dennis",
  "appService": "https://domusograilsweb-dennis.devmuso.com",
  "reservationService": "https://jvy715f729.execute-api.us-east-1.amazonaws.com/dennis",
  "userPoolId": "us-east-1_tuVPUHTxf",
  "authService": "https://2affk4c585.execute-api.us-east-1.amazonaws.com/dennis",
  "userPoolWebClientId": "7j131uo6qmknsnblj9pr1o04it",
  "emailLinkBase": "https://domusograilsweb-dennis.devmuso.com",
  "payWithUrl": "https://paywith.devmuso.com",
  "supportEmail": "support-test@devmuso.com",
  "residentWebUI": "https://resident-dennis.devmuso.com",
  "maintenanceUrl": "https://2affk4c585.execute-api.us-east-1.amazonaws.com/dennis/maintenance",
  "env": "dennis"
}